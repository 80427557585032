nav {
    width: 100%;
    position: fixed;
    bottom: 0;
    /* border-top: thin solid; */
    background-color: white;
    color: #6D6E71;
}

nav ul {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   align-items: center;
   padding: 0;
   list-style: none;
   
}



nav ul li a{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #6D6E71;
   
}

nav ul li a.active, nav ul li a:hover{
    color: #01ABB5;
}
