.list-item {
    /* height: 40px; */
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 10px;
    /* background-color: rgb(30, 216, 194); */
}

.list-item:nth-child(odd){
    background-color: rgb(221, 221, 221);
}