.Activity {
    border-bottom: thin solid rgb(218, 218, 218);
    padding: 20px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */

}

.Activity .actions-wrapper {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    justify-content: flex-end;

}

.Activity.challenge {
    
}

.Activity .title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

