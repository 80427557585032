body {
  margin: 0;
  padding: 0;
  font-family:  "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #59595F !important;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.p-panel .p-panel-titlebar, .p-toolbar {
  background-color: white !important;
  border: none !important;
}
/*
.p-panel .p-panel-content {
  color: #59595F !important;
}
*/
.p-dropdown {
  min-width: 11.5em !important;
} 

.p-button-primary {
  background-color: #01abb5 !important;
    color:white !important;
    border: #01abb5 thin solid !important;
    border-radius: 5px !important;
}

.p-button-secondary {
  border: white solid thin !important;
  color: white !important;
}