.ContenderDetails{

    min-height: 100vh;
    width: 100%;
    position: absolute;
    overflow: scroll;
    top: 0;
  
    background: white;
    /* transition: top 2s; */
    
}

.ContenderDetails .titlebar {
    min-height: 50px;
    display: flex;
    align-items: center;
}

.ContenderDetails .titlebar a {
    cursor: pointer;
    padding: 10px;
    font-size: 1.5em;
    
}

.ContenderDetails .titlebar a i {
    font-size: 40px;
}

.ContenderDetails table {
    width: 100%;
}

.ContenderDetails .task {
    font-weight: bold;
    font-size: 1.0em;
    min-height: 40px;
    display: flex;
    justify-content: left;
    align-items: center;

}

.ContenderDetails .day td{
    background-color: darkgray;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
    
}

.ContenderDetails .day .points {
    font-weight: bold;

}

.ContenderDetails table {
    /* border: thin solid; */
}

.ContenderDetails table tr td {
    /* height: 40px; */
    
    /* border: thin solid; */
}

.ContenderDetails .id-card, .ContenderDetails .history-card, .ContenderDetails .points-card {
    margin: 30px 10px;
    
}

.ContenderDetails .history-card {
    margin-bottom: 100px;
}

.ContenderDetails .p-card-header {
    text-align: center;
}
.ContenderDetails .p-card-header img {
    width: inherit !important;
    margin-top: 20px;

}
