.Activities {
    /* margin-top: 80px; */
}

.Activities ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 160px;
}

.Activities form input {
    width: 100%;
    height: 40px;
}

.Activities form {
    /* text-align: center; */
    
}

.Activities .addNewButton {
    position: fixed;
    bottom: 100px;
    right: 40px;
    width: 60px;
    height: 60px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(240, 129, 11);
    border-radius: 40px;
    font-size: 2.0em;
    border: thin solid rgb(255, 216, 168);
    box-shadow: 1px 1px 3px 0px grey;
}

.Activities .search {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.Activities .search input {
    height: 50px;
    width: 80%;
}

.Activities .search .categoryButtons {
    display: flex;
    /* justify-content: left; */
    align-items: left;
    width: 80%;
    padding-top: 10px;
    justify-content: space-between;
}

.Activities .search .categoryButtons .filterButton {
    text-transform: uppercase;
    padding: 5px;

}

.Activities .search .categoryButtons .filterButton:hover {
    text-transform: uppercase;
    cursor: pointer;
    color: grey !important;
}