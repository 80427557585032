.TitleBar {
    min-height: 60px;
    background-color: #bc7cdc;
    /* margin-bottom: 25px; */
    color: white;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: fixed; */
    width: 100%;
    top: 0;
    flex-direction: row-reverse;
    
    
}

.TitleBar .p-button {
    right: 10px;
    position: absolute;
    

}