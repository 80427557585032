.Company {
    margin-bottom: 80px;
}

.Company .company-title {
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    background-color: #01ABB5;
    color: white;
}

.Company .details {
    margin: 5px;
    margin-bottom: 80px;
    
}

.Company .search {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.Company .search input {
    height: 50px;
    width: 80%;
}

.Company .search .categoryButtons {
    display: flex;
    /* justify-content: left; */
    align-items: left;
    width: 80%;
    padding-top: 10px;
    justify-content: space-between;
}

.Company .search .categoryButtons .filterButton {
    text-transform: uppercase;
    padding: 5px;

}

.Company .search .categoryButtons .filterButton:hover {
    text-transform: uppercase;
    cursor: pointer;
    color: grey !important;
}

.Company .contender {
    display: block;
}