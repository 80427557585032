body {
  margin: 0;
  padding: 0;
  font-family:  "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #59595F !important;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.p-panel .p-panel-titlebar, .p-toolbar {
  background-color: white !important;
  border: none !important;
}
/*
.p-panel .p-panel-content {
  color: #59595F !important;
}
*/
.p-dropdown {
  min-width: 11.5em !important;
} 

.p-button-primary {
  background-color: #01abb5 !important;
    color:white !important;
    border: #01abb5 thin solid !important;
    border-radius: 5px !important;
}

.p-button-secondary {
  border: white solid thin !important;
  color: white !important;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ActionBar {
    /* margin-top: 60px; */
}
li.company-challenge {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px 0;
    border-top: thin solid lightgrey;
}
.Company {
    margin-bottom: 80px;
}

.Company .company-title {
    height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding-left: 10px;
    background-color: #01ABB5;
    color: white;
}

.Company .details {
    margin: 5px;
    margin-bottom: 80px;
    
}

.Company .search {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 10px;
}

.Company .search input {
    height: 50px;
    width: 80%;
}

.Company .search .categoryButtons {
    display: -webkit-flex;
    display: flex;
    /* justify-content: left; */
    -webkit-align-items: left;
            align-items: left;
    width: 80%;
    padding-top: 10px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Company .search .categoryButtons .filterButton {
    text-transform: uppercase;
    padding: 5px;

}

.Company .search .categoryButtons .filterButton:hover {
    text-transform: uppercase;
    cursor: pointer;
    color: grey !important;
}

.Company .contender {
    display: block;
}
.Contenders {
    margin: 10px 10px;
}

.Contenders .p-card {
    margin-left: 10px;
    margin-right: 10px;
}
.Contender a{
    cursor: pointer;
}
.ContenderDetails{

    min-height: 100vh;
    width: 100%;
    position: absolute;
    overflow: scroll;
    top: 0;
  
    background: white;
    /* transition: top 2s; */
    
}

.ContenderDetails .titlebar {
    min-height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.ContenderDetails .titlebar a {
    cursor: pointer;
    padding: 10px;
    font-size: 1.5em;
    
}

.ContenderDetails .titlebar a i {
    font-size: 40px;
}

.ContenderDetails table {
    width: 100%;
}

.ContenderDetails .task {
    font-weight: bold;
    font-size: 1.0em;
    min-height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: center;
            align-items: center;

}

.ContenderDetails .day td{
    background-color: darkgray;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    color: white;
    
}

.ContenderDetails .day .points {
    font-weight: bold;

}

.ContenderDetails table {
    /* border: thin solid; */
}

.ContenderDetails table tr td {
    /* height: 40px; */
    
    /* border: thin solid; */
}

.ContenderDetails .id-card, .ContenderDetails .history-card, .ContenderDetails .points-card {
    margin: 30px 10px;
    
}

.ContenderDetails .history-card {
    margin-bottom: 100px;
}

.ContenderDetails .p-card-header {
    text-align: center;
}
.ContenderDetails .p-card-header img {
    width: inherit !important;
    margin-top: 20px;

}



.list-item {
    /* height: 40px; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: left;
            align-items: left;
    padding: 10px;
    /* background-color: rgb(30, 216, 194); */
}

.list-item:nth-child(odd){
    background-color: rgb(221, 221, 221);
}
div.animain {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 80%;
    width: 100%;
    /* margin-top: 120px; */
  }
  
  .screen {
    position: fixed;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background: white;
    background-repeat: no-repeat;

  }
  
  svg {
    overflow: visible;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 50%;
  }
  
  #head {
    -webkit-animation: moveHead 0.85s ease-in-out infinite alternate;
    animation: moveHead 0.85s ease-in-out infinite alternate;
  }
  
  #person {
    -webkit-animation: movePerson 0.85s ease-in-out infinite alternate;
    animation: movePerson 0.85s ease-in-out infinite alternate;
  }
  
  #hands {
    -webkit-transform-origin: 10% 35%;
            transform-origin: 10% 35%;
    -webkit-animation: rotateHands 0.85s ease-in-out infinite alternate;
    animation: rotateHands 0.85s ease-in-out infinite alternate;
  }
  
  #shin {
    -webkit-transform-origin: 15% 60%;
            transform-origin: 15% 60%;
    webkit-animation: rotateShin 0.85s ease-in-out infinite alternate;
    -webkit-animation: rotateShin 0.85s ease-in-out infinite alternate;
            animation: rotateShin 0.85s ease-in-out infinite alternate;
  }
  
  #body {
    -webkit-transform-origin: 10% 50%;
            transform-origin: 10% 50%;
    webkit-animation: rotateBody 0.85s ease-in-out infinite alternate;
    -webkit-animation: rotateBody 0.85s ease-in-out infinite alternate;
            animation: rotateBody 0.85s ease-in-out infinite alternate;
  }
  
  #_1 {
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
    -webkit-animation-name: lightSpeedIn;
    animation-name: lightSpeedIn;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    animation-iteration-count: initial;
    -webkit-animation-iteration-count: initial;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-timing-function: linear;
  }
  #_2 {
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
    -webkit-animation-name: lightSpeedIn;
    animation-name: lightSpeedIn;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    animation-iteration-count: initial;
    -webkit-animation-iteration-count: initial;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  #_3 {
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
    -webkit-animation-name: lightSpeedIn;
    animation-name: lightSpeedIn;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    animation-iteration-count: initial;
    -webkit-animation-iteration-count: initial;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-timing-function: linear;
  
    -webkit-animation-delay: 1s;
  
            animation-delay: 1s;
  }
  
  #shadow {
    position: absolute;
    top: 56%;
    left: 42.5%;
    -webkit-animation: pulse 0.85s ease infinite alternate;
    animation: pulse 0.85s ease infinite alternate;
  }
  
  @-webkit-keyframes pulse {
    0% {
      width: 6.5em;
    }
    100% {
      width: 6em;
    }
  }
  @keyframes pulse {
    0% {
      width: 6.5em;
    }
    100% {
      width: 6em;
    }
  }
  
  @-webkit-keyframes moveHead {
    0% {
      -webkit-transform: translate(-110px, -180px);
      -moz-transform: translate(-110px, -180px);
      -ms-transform: translate(-110px, -180px);
    }
  
    100% {
      -webkit-transform: translate(-200px, -210px);
      -moz-transform: translate(-200px, -210px);
      -ms-transform: translate(-200px, -210px);
    }
  }
  
  @-webkit-keyframes movePerson {
    0% {
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
    }
  
    100% {
      -webkit-transform: translate(0, -100px);
      -moz-transform: translate(0, -100px);
      -ms-transform: translate(0, -100px);
    }
  }
  
  @-webkit-keyframes rotateHands {
    0% {
      -webkit-transform: rotate(0deg) translate(-120px, -190px);
      transform: rotate(0deg) translate(-120px, -190px);
    }
    100% {
      -webkit-transform: rotate(-190deg) translate(-170px, -110px);
      transform: rotate(-190deg) translate(-170px, -110px);
    }
  }
  @keyframes rotateHands {
    0% {
      -webkit-transform: rotate(0deg) translate(-120px, -190px);
      transform: rotate(0deg) translate(-120px, -190px);
    }
    100% {
      -webkit-transform: rotate(-190deg) translate(-170px, -110px);
      transform: rotate(-190deg) translate(-170px, -110px);
    }
  }
  
  @-webkit-keyframes rotateShin {
    0% {
      -webkit-transform: rotate(-30deg) translate(-130px, -190px);
      transform: rotate(-30deg) translate(-130px, -190px);
    }
    100% {
      -webkit-transform: rotate(0deg) translate(-125px, -190px);
      transform: rotate(0deg) translate(-125px, -190px);
    }
  }
  @keyframes rotateShin {
    0% {
      -webkit-transform: rotate(-30deg) translate(-130px, -190px);
      transform: rotate(-30deg) translate(-130px, -190px);
    }
    100% {
      -webkit-transform: rotate(0deg) translate(-125px, -190px);
      transform: rotate(0deg) translate(-125px, -190px);
    }
  }
  
  @-webkit-keyframes rotateBody {
    0% {
      -webkit-transform: rotate(0deg) translate(-120px, -200px);
      transform: rotate(0deg) translate(-120px, -200px);
    }
    100% {
      -webkit-transform: rotate(-30deg) translate(-110px, -200px);
      transform: rotate(-30deg) translate(-110px, -200px);
    }
  }
  @keyframes rotateBody {
    0% {
      -webkit-transform: rotate(0deg) translate(-120px, -200px);
      transform: rotate(0deg) translate(-120px, -200px);
    }
    100% {
      -webkit-transform: rotate(-30deg) translate(-110px, -200px);
      transform: rotate(-30deg) translate(-110px, -200px);
    }
  }
  
  @-webkit-keyframes lightSpeedIn {
    from {
      -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
      transform: translate3d(100%, 0, 0) skewX(-30deg);
      opacity: 0;
    }
  
    60% {
      -webkit-transform: skewX(20deg);
      transform: skewX(20deg);
      opacity: 1;
    }
  
    80% {
      -webkit-transform: skewX(-5deg);
      transform: skewX(-5deg);
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  @keyframes lightSpeedIn {
    from {
      -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
      transform: translate3d(100%, 0, 0) skewX(-30deg);
      opacity: 0;
    }
  
    60% {
      -webkit-transform: skewX(20deg);
      transform: skewX(20deg);
      opacity: 1;
    }
  
    80% {
      -webkit-transform: skewX(-5deg);
      transform: skewX(-5deg);
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
nav {
    width: 100%;
    position: fixed;
    bottom: 0;
    /* border-top: thin solid; */
    background-color: white;
    color: #6D6E71;
}

nav ul {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row;
           flex-direction: row;
   -webkit-justify-content: space-around;
           justify-content: space-around;
   -webkit-align-items: center;
           align-items: center;
   padding: 0;
   list-style: none;
   
}



nav ul li a{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    color: #6D6E71;
   
}

nav ul li a.active, nav ul li a:hover{
    color: #01ABB5;
}

.TitleBar {
    min-height: 60px;
    background-color: #bc7cdc;
    /* margin-bottom: 25px; */
    color: white;
    text-transform: capitalize;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    /* position: fixed; */
    width: 100%;
    top: 0;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    
    
}

.TitleBar .p-button {
    right: 10px;
    position: absolute;
    

}
.Activities {
    /* margin-top: 80px; */
}

.Activities ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 160px;
}

.Activities form input {
    width: 100%;
    height: 40px;
}

.Activities form {
    /* text-align: center; */
    
}

.Activities .addNewButton {
    position: fixed;
    bottom: 100px;
    right: 40px;
    width: 60px;
    height: 60px;
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: rgb(240, 129, 11);
    border-radius: 40px;
    font-size: 2.0em;
    border: thin solid rgb(255, 216, 168);
    box-shadow: 1px 1px 3px 0px grey;
}

.Activities .search {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 10px;
}

.Activities .search input {
    height: 50px;
    width: 80%;
}

.Activities .search .categoryButtons {
    display: -webkit-flex;
    display: flex;
    /* justify-content: left; */
    -webkit-align-items: left;
            align-items: left;
    width: 80%;
    padding-top: 10px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Activities .search .categoryButtons .filterButton {
    text-transform: uppercase;
    padding: 5px;

}

.Activities .search .categoryButtons .filterButton:hover {
    text-transform: uppercase;
    cursor: pointer;
    color: grey !important;
}
.Activity {
    border-bottom: thin solid rgb(218, 218, 218);
    padding: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    /* justify-content: space-between; */

}

.Activity .actions-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding-top: 5px;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;

}

.Activity.challenge {
    
}

.Activity .title-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}


.Users ul {
    padding: 0;
}
.User {
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 20px;
    border-bottom: thin lightgrey solid;

}

.Login {
    margin-top: 70px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}
.Layout {
    
}
.Companies {
    
}
.Company {
    
}
